import Service from "../Service";
const resource = "equipmentassignment/";

export default {

    verification(obj, requestID) {
        return Service.post(resource + "verification", obj, {
            params: { requestID: requestID },
        });
    },

    pagination(obj, requestID) {
        return Service.post(resource + "pagination", obj, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },
    assignQr(teaID, requestID) {
        return Service.post(resource + "assignqr", {}, {
            params: { teaID: teaID, requestID: requestID },
        });
    },
};