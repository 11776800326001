
<template>
	<div>
		<v-card>
		
			<v-card-text>
				<b>Verificación Equipo - Trabajador</b>
			</v-card-text>
			
			<v-container>
				<v-row>
					<v-col cols="12">
						<s-scanner-qr
							:config="configScann"
							autofocus
							return-object
							@onValue="onValue($event)"
							@onData="onData($event)"
						></s-scanner-qr>
					</v-col>
				</v-row>
			</v-container>
		</v-card>

		<v-card
			class="mx-auto"
			outlined
			v-if="isresponse"
			width="500"
		>
			<v-card-title v-if="data.TeaStatus == 1" class="green justify-center text-h4 white--text align-center">
				<strong>{{data.AuthorizationName}}</strong>
			</v-card-title>

			<v-card-title v-if="data.TeaStatus == 2" class="red justify-center text-h4 white--text align-center">
				<strong>{{data.AuthorizationName}}</strong>
			</v-card-title>
			<v-row justify="center">
				<v-col lg="12" md="12" sm="6" align="center">
					<v-img
						v-if="image == ''"
						min-width="150"
						height="250"
						src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
					></v-img>
					<v-img
						v-else
						width="150"
						min-width="150"
						height="150"
						:src="image"
					></v-img>
				</v-col>
				<v-col lg="12" md="12" sm="12">
					<v-list-item three-line>
						<v-list-item-content>
							<v-list-item-title class="text-h5 mb-1">
								{{data.WorkerName}}
							</v-list-item-title>
							<v-list-item-subtitle  class="text-h5 mb-1">
								<b>Marca: </b> {{data.TeaBrand}}
							</v-list-item-subtitle>
							<v-list-item-subtitle  class="text-h5 mb-1">
								<b>Serie: </b> {{data.TeaSerie}}
							</v-list-item-subtitle>
							<v-list-item-subtitle  class="text-h5 mb-1">
								<b>Modelo: </b> {{data.TeaModel}}
							</v-list-item-subtitle>
							
						</v-list-item-content>
					</v-list-item>
				</v-col>
			</v-row>
			<v-container>
				
			</v-container>
		
			<!-- <v-container>
				<v-row>
					<v-col cols="12">
						<v-alert
							dense
							:type="data.TeaStatus == 1 ? 'success' : 'error'"
							>
								<strong>{{data.AuthorizationName}}</strong>
						</v-alert>
					</v-col>
				</v-row>
				
			</v-container> -->
		</v-card>
		
		
	</div>
</template>

<script>
	import _sEquipmentAssignmentService from '@/services/InformationTechnology/EquipmentAssignmentService';
	import HelperService from "@/services/HelperService";

	export default {
		data() {
			return {
				configScann: {},
				data: {},
				isresponse: false,
				image: ""
			}
		},

		methods: {
			onValue(val) {
				if (val == "Sin datos" || isNaN(val)) {
					this.$fun.alert("El QR Leido no contiene datos", "warning");
				} else {
					
				}
			},

			onData(val) {
				let obj = {
					TeaID: val
				}
				_sEquipmentAssignmentService
				.verification(obj, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.data = resp.data;
                        
                        if(this.data.TeaStatus == 2)
						{
							this.$fun.soundAlert();
						}
						this.isresponse = true;

						if(this.data.WrkID != null)
						{
							HelperService.getPhotoWorker(
								this.data.WrkID,
								this.$fun.getUserID()
							).then((rsp) => {

								const blob = new Blob([rsp.data], {
								type: "image/jpg",
								});
								let image = blob;
								var reader = new FileReader();
								reader.readAsDataURL(image);
								reader.onloadend = () => {
								this.image = reader.result;
								console.log("🚀 ~ file: ScannerEquipmentAssignment.vue ~ line 144 ~ ).then ~ this.image", this.image)
								
								};
							});
						}
						
					}
				})
			},
		},
	}
</script>
